import React, { useState, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import ChatApp from "./RetrievalApp/RetrievalApp";

const BASE_URL = process.env.REACT_APP_API_BASE_URL_AUTH;

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      setUser(JSON.parse(loggedInUser));
    }
  }, []);

  const handleLoginSuccess = async (googleResponse) => {
    const tokenId = googleResponse?.credential;

    try {
      const response = await fetch(
        BASE_URL + "/auth/validate_google_token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token_id: tokenId }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("user", JSON.stringify(data.user_id));
        setUser(data.user_id);
      } else {
        throw new Error(
          data.message || "You are not authorized to access this application."
        );
      }
    } catch (error) {
      console.error("Error verifying user: ", error.message);
      alert(error.message);
    }
  };

  const handleLoginFailure = (error) => {
    console.log("Login failed:", error);
  };

  if (!user) {
    return (
      <div>
        <h2>Musculo Mac Retrieval Tool</h2>
        <br />
        <br />
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
          useOneTap
        />
      </div>
    );
  } else {
    return <ChatApp user={user} />;
  }
}

export default App;

import React, { useState } from 'react';
import axios from 'axios';

import './RetrievalApp.css';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RetrievalApp = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    setError('');
    setResults([]);
    setIsLoading(true);
    try {
      const response = await axios.post(BASE_URL + '/api/search/', {
        query,
        table_name: "all_context",
        search_type: "cosine",
        k: 5,
        metadata_colnames: ["source", "section", "subsection", "minisection", "created_at", "id"],
        content_colname: "content"
      });
      setResults(response.data);
      if (response.data.length === 0) {
        setError('No results found for your query.');
      }
    } catch (error) {
      setError('Failed to fetch results. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="RetrievalApp">
      <h1>Musculo Mac Retrieval Tool</h1>
      <div className="search-box">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Type your query"
          className="search-input"
        />
        <button onClick={handleSearch} className="search-button" disabled={isLoading}>
          {isLoading ? 'Searching...' : 'Search'}
        </button>
      </div>
      {error && <div className="error">{error}</div>}
      <div className="results">
        {results.length > 0 ? (
          results.map((result, index) => (
            <div key={index} className="result-item">
              <h3 className="result-title">{result.metadata.source}</h3>
              <p className="result-content">{result.page_content}</p>
              <p className="result-score">Match Score: {result.metadata.score}</p>
              <a href={result.metadata.source || '#'} target="_blank" rel="noopener noreferrer" className="result-link">Document Link</a>
            </div>
          ))
        ) : !isLoading && (
          <p className="no-results">Please enter a query to search the retrieval API.</p>
        )}
      </div>
    </div>
  );
};

export default RetrievalApp;